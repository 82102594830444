body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
/* add scrollbar colors */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #8561c5;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #673AB7;
}